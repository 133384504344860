.tempImg {
    border-radius: 10px;
    width: 100%;
    
    object-fit: cover;
    object-position: center center;
}
.projectCont {
    flex: none;
    align-self: stretch;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    gap: 10px;

}