$primary-color: rgb(255, 237, 222);
$background-color: rgb(17, 17, 17);

.workProject {
    display: flex;
    flex-direction: row;
    text-align: left;
    gap: 20px;
    justify-content: space-between;
}

.workProjectHead {
    width: 50%;
    display: flex;
    flex-direction: column;
    gap: 40px;

    div {
        border-bottom: 1px solid $primary-color;
        border-top: 1px solid $primary-color;
        padding: 10px 0;

        h2 {
            margin-bottom: 5px;
        }
        p {
            font-size: 20px;
        }
    }

    a {
        color: $primary-color;
    }
}

.workImgCont {
    width: 50%;
}
.workImg {
    width: 100%;
    border-radius: 10px;
}
div[id='av'] > .workProject{
    flex-direction: row-reverse;
}

.workProjectBtn {
    text-align: left;
    width: fit-content;
    border-bottom: 1px solid $primary-color;
}

@media only screen and (max-width: 768px) {
    .workProject {
        flex-direction: column !important;
    }
    .workImgCont {
        width: 100%;
    }
    .workImg {
        width: 100%; 
    }
    .workProjectHead {
        width: 100%;
        gap: 20px;
    }
    div[id='av'] {
        flex-direction: column-reverse;
    }
}