$primary-color: rgb(255, 237, 222);
$background-color: rgb(17, 17, 17);

.contactCont {
    padding: 30px 20px;
    margin: 60px auto;
}

.contactOuter {
    position: relative;
}
.contactInner {
    position: absolute;
    top: 50%;
    left: 50%;
    background-color: $background-color;
    transform: translate(-50%,-50%);
    padding: 10px;
}

.contactHead {
    margin-bottom: 20px;
}

.contactForm {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 20px;
}
.contactInput {
    background-color: $background-color;
    border: none;
    border-bottom: 1px solid $primary-color;
    width: 39%;
    min-width: 600px;
    height: 40px;
    color: white;
}
textarea {
    height: 100px !important;
    resize: none;
}

.contactSubmit {
    background-color: $background-color;
    border: none;
    border: 1px solid $primary-color;
    width: 39%;
    min-width: 600px;
    height: 40px;
    color: $primary-color;
    cursor: pointer; 
}
.contactSubmit:hover {
    background-color: rgba(255, 237, 222,.1);
}


@media only screen and (max-width: 768px) {
    textarea {
        height: 75px !important;
    }
    .contactCont {
        margin: 40px 0px;
        padding: 30px 0px;
    }
    .contactInput {
        min-width: 275px;
    }  
    .contactSubmit {
        min-width: 275px;
    }
}