$primary-color: rgb(255, 237, 222);
$background-color: rgb(17, 17, 17);

.navCont {
    display: flex;
    flex-direction: row;
    // border-bottom: 1px solid white;
    align-items: center;
    justify-content: space-between;
    padding: 10px 20px;

    .navLinksCont {
        display: flex;
        gap: 20px;
    }
}

button {
    font-family: "Kode Mono", monospace;
    text-decoration: none;
    color: $primary-color;
    background-color: transparent;
    border: none;
    cursor: pointer;
}
button:hover {
    color: rgb(255, 255, 255);
}

button[id='contactBtn'] {
    background-color: $primary-color;
    color: $background-color;
    padding: 6px 16px;
    border-radius: 16px;
    font-weight: 700;
}