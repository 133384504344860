$primary-color: rgb(255, 237, 222);
$background-color: rgb(17, 17, 17);

.worksCont {
    padding: 20px;
    
}
.worksHero {
    display: flex;
    flex-direction: row;
    text-align: left;
    gap: 20px;
    margin: 40px 0;
    width: 100%;
    align-items: center;
    
    h1 {
        margin-bottom: 20px;
    }
    p {
        font-size: 20px;
    }
}
.workProjectCont {
    margin-bottom: 60px;
    display: flex;
    flex-direction: column;
    gap: 40px;
}
.workProject {
    display: flex;
    flex-direction: row;
    text-align: left;
    gap: 20px;
    justify-content: space-between;
}
.workProjectHead {
    width: 50%;
    display: flex;
    flex-direction: column;
    gap: 40px;

    div {
        border-bottom: 1px solid $primary-color;
        border-top: 1px solid $primary-color;
        padding: 10px 0;

        h2 {
            margin-bottom: 5px;
        }
        p {
            font-size: 20px;
        }
    }
}
.workImgCont {
    width: 50%;
}
.workImg {
    width: 100%;
    border-radius: 10px;
}

.opposite {
    flex-direction: row-reverse !important;
}

@media only screen and (max-width: 768px) {
    .workProject {
        flex-direction: column;
    }
    .workImgCont {
        width: 100%;
    }
    .workImg {
        width: 100%; 
    }
    .worksHero {
        flex-direction: column;
    }
    .workProjectHead {
        width: 100%;
        gap: 20px;
    }
    div[id='av'] {
        flex-direction: column-reverse;
    }
}