$primary-color: rgb(255, 237, 222);
$background-color: rgb(17, 17, 17);

.App {
  position: relative;
  text-align: center;
  background-color: $background-color;
  color: $primary-color;
  min-height: 100vh;
}
.AppCont {
  position: relative;
  max-width: 1440px;
  margin: auto;
  min-height: 100vh;
}

.loaderCont {
  position: absolute;
  top: 45%;
  left: 50%;
  transform: translate(-50%, -50%);
}

/* HTML: <div class="loader"></div> */
.loader {
  width: 100px;
  aspect-ratio: 1.154;
  display: grid;
  color: $primary-color;
  background:
    linear-gradient(to bottom left ,#0000 calc(50% - 1px),currentColor 0 calc(50% + 1px),#0000 0) right/50% 100%,
    linear-gradient(to bottom right,#0000 calc(50% - 1px),currentColor 0 calc(50% + 1px),#0000 0) left /50% 100%,
    linear-gradient(currentColor 0 0) bottom/100% 2px;
  background-repeat: no-repeat;
  transform-origin: 50% 66%;
  animation: l5 4s infinite linear;
  //transform: translate(-50%, -50%);
}
.loader::before,
.loader::after {
  content: "";
  grid-area: 1/1;
  background: inherit;
  transform-origin: inherit;
  animation: inherit;
}
.loader::after {
  animation-duration: 2s;
}
@keyframes l5{
  100% {transform:rotate(1turn)}
}