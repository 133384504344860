$primary-color: rgb(255, 237, 222);
$background-color: rgb(17, 17, 17);
// <uniquifier>: Use a unique and descriptive class name
// <weight>: Use a value from 400 to 700

// .kode-mono-<uniquifier> {
//     font-family: "Kode Mono", monospace;
//     font-optical-sizing: auto;
//     font-weight: <weight>;
//     font-style: normal;
// }

// :root {
//     --animate-duration: 800ms;
//     --animate-delay: 0.9s;
// }
.animate__animated.animate__fadeInUp {
    --animate-duration: 7s;
}

.hidden {
    opacity: 0;
}

.homeCont {
    display: flex;
    flex-direction: column;
    text-align: left;
    padding: 20px;
    min-height: 100%;
    gap: 20px;
    
    a {
        text-decoration: none;
        color: $primary-color;
    }

    section {
        display: flex;
        flex-direction: row;
        align-items: center;
        width: 100%;
        margin-bottom: 60px;
    }
    
    .homeHeroCont {
        max-width: 350px;
        height: 80%;
        display: flex;
        flex-direction: column;
        gap: 30px;
    }
    .homeHeroContTwo {
        width: 100%;
        display: flex;
        flex-direction: column;
        gap: 40px;
        background-color: $primary-color;
        color: $background-color;
        border-radius: 20px;
        padding: 40px;
    }
    .homeHeroContThree {
        width: 100%;
    }

    .hero {
        display: flex;
        flex-direction: row;
        align-items: center;
        width: 100%;
        justify-content: space-between;

    }

    h2 {
        font-weight: 700;
    }
    h3 {
        font-weight: 400;
    }

    .buffer {
        text-align: center;
        margin: auto;
        max-width: 950px;
        margin-bottom: 40px;
    }

    .processes {
        display: flex;
        flex-direction: row;
        gap: 20px;
        justify-content: space-around;
        flex:2;
    }
    .processStep {
        border: 1px solid rgba(0, 0, 0, .1);
        border-radius: 20px;
        display: flex;
        flex-direction: column;
        gap: 15px;
        padding: 50px 30px 120px 30px;
        position: relative;
        width: 50%;
        transition: .5s all;
        p {
            text-align: left;
        }
        img {
            position: absolute;
            bottom: 20px;
            left: 50%;
            transform: translate(-50%, 0);
            margin: auto;
            width: 80px;
            transition: .5s all;
        }
    }
    .processStep:hover {
        border: 1px solid rgba(0, 0, 0, .5);
        img {
            width: 90px;
        }
    }
    .processCont {
        display: flex;
        flex-direction: row;
        gap: 20px;
        flex-wrap: nowrap;
        width: 50%;
    }

    ul {
        list-style-type: none;
    }
    .listsCont {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        width: 100%;
        gap: 60px;
    }
    .projectList {
        display: flex;
        flex-direction: column;
        flex-wrap: wrap;
        align-items: stretch;
        justify-content: flex-start;
        width: 50%;
        gap: 100px;
    }

    .tempDiv {
        width: 50%;
        height: 50px;
        box-sizing: border-box;
    }

    .blogCont {
        width: 100%;
        h2 {
            margin-bottom: 20px;
        }
    }
    .blogFeed {
        display: flex;
        flex-direction: column;
    }
    .blogPost {
        margin-bottom: 33px;
        display: flex;
        flex-direction: row;
        align-items:baseline;
        justify-content: space-between;
        color: $background-color;
        text-decoration: none;
        border-bottom: 1px solid $background-color;
        padding: 10px 0;
    }
    .blogPost:hover {
        color: rgb(126, 126, 126);
    }

    .contactCont {
        width: 30%;
        display: flex;
        flex-direction: column;
        gap: 20px;
        margin-bottom: 60px;
    }

    .contactEmail {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        border-top: 1px solid $primary-color;
        border-bottom: 1px solid $primary-color;
        padding: 10px 0;
        img {
            width: 30px;
            filter: invert(80%);
        }
    }
    .contactEmail:hover {
        color: rgb(153, 153, 153)
    }

}
.loader {
    width: 50px;
    aspect-ratio: 1.154;
    display: grid;
    color: #000;
    background:
      linear-gradient(to bottom left ,#ffffff00 calc(50% - 1px),currentColor 0 calc(50% + 1px),#ffffff00 0) right/50% 100%,
      linear-gradient(to bottom right,#ffffff00 calc(50% - 1px),currentColor 0 calc(50% + 1px),#ffffff00 0) left /50% 100%,
      linear-gradient(currentColor 0 0) bottom/100% 2px;
    background-repeat: no-repeat;
    transform-origin: 50% 66%;
    animation: l5 4s infinite linear;
  }
  .loader::before,
  .loader::after {
    content: "";
    grid-area: 1/1;
    background: inherit;
    transform-origin: inherit;
    animation: inherit;
  }
  .loader::after {
    animation-duration: 2s;
  }
  @keyframes l5{
    100% {transform:rotate(1turn)}
  }


@media only screen and (max-width: 1200px) {
    .processes {
        flex-direction: column !important;
    }
    .processCont {
        width: 100% !important;
    }
}

@media only screen and (max-width: 768px) {
    .processCont {
        flex-direction: column !important;
        width: 100%;
    }
    .processStep {
        width: 100% !important;
        box-sizing: border-box;
    }
    .hero {
        gap: 20px;
        flex-direction: column !important;
    }
    .homeCont {
        margin-top: 80px;
    }
    .blogPost {
        span {
            display: none;
        }
    }
    .listsCont {
        flex-direction: column !important;
        margin-top: 30px;
    }
    .projectList {
        width: 100% !important;
    }
    .tempDiv {
        display: none;
    }
    .contactCont {
        width: 100% !important;
    }
}