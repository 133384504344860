$primary-color: rgb(255, 237, 222);

.footerCont {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding: 30px 20px;
    width: 100%;
    box-sizing: border-box;
    position: absolute;
    bottom: 0;
    .footerLinksCont {
        display: flex;
        gap: 15px;
        align-items: center;
        box-sizing: border-box;
        a {
            text-decoration: none;
            color: $primary-color;
        }
        a:hover {
            color: white;
        }
        img {
            width: 20px;
            filter: invert(90%);
        }
        #fb {
            width: 25px !important;
            filter: invert(100%) !important;
        }
    }
}