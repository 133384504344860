$primary-color: rgb(255, 237, 222);
$background-color: rgb(17, 17, 17);

.aboutCont {
    padding: 100px 20px 60px 20px;
    display: flex;
    flex-direction: column;
    gap: 60px;
}

.aboutSect {
    text-align: left;
}

.aboutBuffer {
    border-bottom: 1px solid $primary-color;
    border-top: 1px solid $primary-color;
    height: 25px;
    box-sizing: border-box;
    width: 100%;
    overflow: hidden;
    canvas {
        opacity: .5;
    }
}

.aboutSectText {
    width: 75%;
    display: flex;
    flex-direction: column;
    gap: 20px;
}
.aboutClientsCont {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    gap: 60px;
    width: 100%;
}
.aboutClientImg {
    width: 40%;
    object-fit: fill;
}

.aboutProjects {
    display: flex;
    flex-direction: row;
    margin-bottom: 100px;
    justify-content: space-between;
    gap: 60px;
}
.aboutProjectsText {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    min-width: 300px;
}
.worksLink {
    border-top: 1px solid $primary-color;
    border-bottom: 1px solid $primary-color;
    padding: 10px 0;
}

.aboutHead {
    display: flex;
    flex-direction: column;
    gap: 20px;
}

.ueImg {
    width: 40%;
    object-fit: cover !important;
}

@media only screen and (max-width: 768px) {
    .aboutProjects {
        flex-direction: column;
    }
    .aboutHead {
        gap: 20px;
        h1 {
            font-size: 42px;
        }
        h2 {
            font-size: 20px;
            border-bottom: 1px solid $primary-color;
            border-top: 1px solid $primary-color;
            padding: 5px 0;
        }
    }

}